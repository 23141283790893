import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const ProjectsPage = () => {
  return (
    <Layout>
      <SEO title="Projects" />
      <h1>Projects</h1>
      <p>
        See my work at{" "}
        <OutboundLink href="https://github.com/kyrsideris" target="_blank" rel="noopener noreferrer">
          github.com/kyrsideris
        </OutboundLink>
      </p>
    </Layout>
  )
}

export default ProjectsPage
